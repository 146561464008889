<template>
  <div class="main">
    <el-row justify="end">
      <el-button size="small" style="margin-bottom:20px;" type="primary" @click="edit(-1)">新建评测</el-button>
    </el-row>
    <el-table
      border
      :data="state.tableData"
      size="small"
      :header-cell-style="{ background: '#f7fbff', color: '#000000d9' }"
      style="width: 100%"
      highlight-current-row
    >
      <el-table-column prop="id" label="评测ID" />
      <el-table-column prop="title" label="评测名称" />
      <el-table-column prop="type" label="评测类型" />
      <el-table-column prop="itemNums" label="评测题目数" />
      <el-table-column prop="name" label="状态">
        <template v-slot="scope">
          <span v-if="scope.row.status == 1">正常</span>
          <span v-if="scope.row.status == 3" style="color: #fc9100">待发布</span>
          <span v-if="scope.row.status == 2" style="color: #ff554f">已关闭</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="text" @click="handleCopy(scope.row.id)" :style="{ color: '#6C72FF' }" v-if="scope.row.type !== 'MBTI测评'">复制</el-button>
          <el-button
            v-if="scope.row.status == 2"
            type="text"
            @click="recovery(scope.row.id)"
            :style="{ color: '#FC9100' }"
          >恢复</el-button>
          <el-button
            v-if="scope.row.status == 3"
            type="text"
            @click="release(scope.row.id)"
            :style="{ color: '#64CCA9' }"
          >发布</el-button>
          <el-button type="text" :style="{ color: '#71A8FF' }" @click="edit(scope.row.id)">编辑</el-button>
          <el-button
            type="text"
            v-if="scope.row.status != 2"
            @click="delItem(scope.row.id)"
            :style="{ color: '#F04100' }"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="state.currentPage"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="state.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.total"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog v-model="state.centerDialogVisible" title="添加测评" width="500px" center>
      <p>
        测评名称
        <span style="color: red; margin: 10px 0; display: inline-block">*</span>
      </p>
      <el-input
        v-model="state.title"
        :maxlength="80"
        :rows="2"
        type="textarea"
        placeholder="请输入测评名称"
      />
      <p>
        量表类型
        <span style="color: red; margin: 10px 0; display: inline-block">*</span>
      </p>
      <el-select v-model="state.value" placeholder="Select" style="width:450px">
        <el-option key="1" label="单维度测评" value="单维度测评"></el-option>
        <el-option key="2" label="多维度测评" value="多维度测评"></el-option>
        <el-option key="3" label="MBTI测评" value="MBTI测评"></el-option>
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submit">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { reactive, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { setToken, getToken } from '@/utils/auth'
import { ElMessageBox, ElMessage } from "element-plus";
import { CODE } from "@/config"
import { getOssInfo } from "../../utils/ossInfo";
// import axios from 'axios'
export default {
  name: "manage",
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      centerDialogVisible: false,
      title: '',
      value: '单维度测评',
      currentPage: 1,
    });

    // const getTokenReq = () => {
    //   let data = {
    //     channel:"h5",
    // 		phone:"17722904579",
    // 		code:"1111",
    // 		orgId:88888888
    //   }
    //   axios.post("https://api-test.westlake.ink/user/api/client/loginByPhone",data).then(res=>{
    //     let token = res.data.data.token
    //     setToken(token)
    //     getSubjectInfo();
    //   })
    // }

    // getTokenReq()  

    onBeforeMount(() => {
      // if (getToken()) {
      //   console.log('存在')
      //   getSubjectInfo();
      // }else{
      //   console.log('不存在')
      const search = new URLSearchParams(location.search)
      sessionStorage.setItem("userId", search.get('id'))
      // sessionStorage.setItem("userId", '36443040977487616')
        console.log('获取到的userid', search.get('id'))
      getOssInfo()
      // setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDMwOTgwNDAsImlhdCI6NDMyMDAwLCJpZGVudGl0eSI6ImFkbWluIiwidXNlcklkIjozNjQ0MzA0MDk3NzQ4NzYxNn0.WyfUhgLqAASXnCsynKeBQHs42ztJNHGoh_nK28GpMnM')
      setToken(search.get("token"))
      getSubjectInfo();
      // }
    });

    const getSubjectInfo = () => {
      let data = {
        page: state.page,
        size: state.size,
      };

      store.dispatch("manager/subjectInfo", data).then((res) => {
        console.log(res);
        state.tableData = res.data.values;
        if (res.data.total) {
          state.total = res.data.total * 1;
        } else {
          state.total = 0
        }
      });
    };
    const handleSizeChange = (val) => {
      state.size = val
      getSubjectInfo();
      console.log(val)
    }
    const handleCurrentChange = (v) => {
      state.page = v;
      getSubjectInfo();
    };

    const delReq = (id) => {
      store.dispatch("manager/subjectClose", id).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          getSubjectInfo();
          ElMessage({
            type: "success",
            message: "删除成功",
          });
        }
      });
    };
    const delItem = (v) => {
      console.log(v);
      ElMessageBox.confirm("确认要把该条记录删除吗?", "确认删除", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delReq(v);
        })
        .catch(() => {

        });
    };
    const recReq = (id) => {
      store.dispatch("manager/subjectRecover", id).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          getSubjectInfo();
          ElMessage({
            type: "success",
            message: "恢复成功",
          });
        }
      });
    };
    const recovery = (v) => {
      ElMessageBox.confirm("确认要把该条记录恢复吗?", "确认恢复", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          recReq(v)
        })
        .catch(() => {

        });
    };
    const submit = () => {
      if (!state.title) {
        ElMessage({
          message: '请输入测评名称',
          type: 'warning',
        })
        return
      }
      router.push({ path: "edit", name: "edit", query: { id: -1, title: state.title, text: state.value } });
    }
    const edit = (id) => {
      if (id == -1) {
        state.centerDialogVisible = true
        return

      } else {
        router.push({ path: "edit", name: "edit", query: { id, text: -1, title: -1 } });
      }
    };
    //发布
    const relReq = (id) => {
      store.dispatch("manager/subjectPublish", id).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          getSubjectInfo();
          ElMessage({
            type: "success",
            message: "发布成功",
          });
        }
      });
    };
    const release = (id) => {
      ElMessageBox.confirm("确认要发布吗?", "确认发布", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          relReq(id)
        })
        .catch(() => {

        });
    };
    const copyOne = (id) => {
      store.dispatch("manager/subjectCopy", id).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          getSubjectInfo();
          // edit(res.data.id)
          ElMessage({
            type: "success",
            message: "复制成功",
          });
        }
      });
    };
    const handleCopy = (id) => {
      ElMessageBox.confirm("确认要复制吗?", "确认发布", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          copyOne(id)
        })
        .catch(() => {

        });
    }

    return {
      state,
      delItem,
      recovery,
      handleCurrentChange,
      edit,
      release,
      handleCopy,
      submit,
      handleSizeChange
    };
  },
};
</script>

<style scoped>
.pagination {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.main {
  background: white;
  padding: 24px;
}
* {
  font-size: 14px;
}
.card {
  position: absolute;
  top: 80px;
}
</style>
